import * as UI from "@iyk/ui"

export const ItemMedia = ({
  className,
  ...props
}: React.ComponentProps<typeof UI.Media>) => {
  return <UI.Media {...props} className={classForItemMedia({ className })} />
}

const classForItemMedia = UI.cva(
  "aspect-square object-contain rounded-none overflow-hidden bg-gray-2",
)
