import type { CartData } from "../../lib/shopify/cart.server.ts"

import * as React from "react"

import { CartNotification } from "./cart-notification.tsx"
import { Cart } from "./cart.tsx"

const CartContext = React.createContext<CartContextValue | null>(null)

function CartProvider({
  cart,
  children,
}: {
  cart: Promise<CartData | null>
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <CartContext.Provider
      value={{
        cart,
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }}
    >
      {children}
      <Cart />
      <CartNotification />
    </CartContext.Provider>
  )
}

function useCart() {
  const context = React.useContext(CartContext)
  if (!context) {
    throw new Error("useCart must be used within a CartProvider")
  }
  return context
}

type CartContextValue = {
  cart: Promise<CartData | null>
  isOpen: boolean
  open: () => void
  close: () => void
}

export { CartProvider, useCart }
