import type { SelectedOption } from "@shopify/hydrogen/storefront-api-types"
import type {
  ProductFragment,
  ProductVariantFragment,
} from "./types/storefront.generated"

/* This is the default option for a product with no variants. */
const SHOPIFY_DEFAULT_OPTION: ProductOption = { name: "Title", value: "Default Title" }

/**
 * Get a formatted string of variant options.
 *
 * @param options - The variant options to format.
 * @returns A formatted string of variant options.
 */
export function getFormattedVariantOptions(options: SelectedOption[]) {
  return options
    .filter((option) => getOptionKey(option) !== getOptionKey(SHOPIFY_DEFAULT_OPTION))
    .map((option) => option.value)
    .join(" • ")
}

/**
 * Get the default variant from a list of variants.
 *
 * @param variants - The list of variants to search through.
 * @returns The default variant.
 */
export function getDefaultVariant(variants: Array<ProductVariantFragment>) {
  return variants.find((v) => v.availableForSale)
}

/**
 * Get a variant from a list of variants based on the selected options.
 *
 * @param args - The arguments to search for the variant.
 * @returns The variant that matches the selected options.
 */
export function getVariantFromSelectedOptions(args: {
  variants: Array<ProductVariantFragment>
  selectedOptions: ProductOption[]
}) {
  const { variants, selectedOptions } = args

  return (
    variants.find((v) =>
      selectedOptions.every((o) =>
        v.selectedOptions.find((vo) => getOptionKey(vo) === getOptionKey(o)),
      ),
    ) ?? null
  )
}

/**
 * Get the options available for sale from a list of variants.
 *
 * @param variants - The list of variants to search through.
 * @returns A map of options available for sale.
 */
export function getOptionsAvailableForSale(variants: Array<ProductVariantFragment>) {
  const optionsMap = variants.reduce<Map<string, ProductOption>>((acc, variant) => {
    if (variant.availableForSale) {
      const variantOptions = variant.selectedOptions.map((option) => ({
        option,
        key: getOptionKey(option),
      }))

      variantOptions.forEach((option) => {
        acc.set(option.key, option.option)
      })
    }

    return acc
  }, new Map())

  return Array.from(optionsMap.values())
}

/** Generate a unique key for a product option. */
export function getOptionKey(option: ProductOption) {
  return `${option.name}-${option.value}`
}

export type ProductOption = ProductVariantFragment["selectedOptions"][number]
export type ProductOptionWithValues = ProductFragment["options"][number]
export type ProductWithVariants = ProductFragment & {
  variants: { nodes: ProductVariantFragment[] }
}
