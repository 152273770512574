import type { CartLineUpdateInput } from "@shopify/hydrogen/storefront-api-types"
import type { CartData } from "../../lib/shopify/cart.server.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as Shopify from "../../lib/shopify/shopify.ts"
import * as Sheet from "../../lib/ui/sheet.tsx"

import { Await, Link } from "@remix-run/react"
import { CartForm, useMoney } from "@shopify/hydrogen"
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICES_URL } from "../../lib/constants.ts"
import { useCart } from "./cart-provider.tsx"

function Cart() {
  const { cart } = useCart()

  return (
    <CartSheet>
      <React.Suspense fallback={<CartFallback />}>
        <Await resolve={cart}>{(cartData) => <CartMain cart={cartData} />}</Await>
      </React.Suspense>
    </CartSheet>
  )
}

function CartMain({ cart }: { cart: CartData | null }) {
  const totalQuantity = cart?.totalQuantity ?? 0

  return (
    <div className="h-full flex flex-col">
      <CartHeader totalQuantity={totalQuantity} />
      {cart && totalQuantity > 0 ? (
        <React.Fragment>
          <CartSectionDivider />
          <CartLineList lines={cart.lines.edges} />
          <CartSectionDivider />
          <CartFooter checkoutUrl={cart.checkoutUrl} cost={cart.cost} />
        </React.Fragment>
      ) : (
        <CartEmpty />
      )}
    </div>
  )
}

function CartSheet({ children }: { children: React.ReactNode }) {
  const cartContext = useCart()
  const onOpenChange = (newIsOpen: boolean) =>
    newIsOpen ? cartContext.open() : cartContext.close()

  return (
    <Sheet.Root open={cartContext.isOpen} onOpenChange={onOpenChange}>
      <div className="sr-only">
        <Sheet.Title>Bag</Sheet.Title>
        <Sheet.Description>
          View and manage items in your shopping bag
        </Sheet.Description>
      </div>
      <Sheet.Content className="h-full flex flex-col" size="none">
        {children}
      </Sheet.Content>
    </Sheet.Root>
  )
}

function CartFallback() {
  return (
    <UI.Text size="sm" className="text-gray-11 px-3">
      Bag is loading...
    </UI.Text>
  )
}

function CartHeader({ totalQuantity }: { totalQuantity: CartData["totalQuantity"] }) {
  return (
    <div className="flex items-center gap-2 px-3 pt-3 mb-8">
      <UI.Text size="xs">Bag</UI.Text>
      <UI.Text size="xs">{totalQuantity}</UI.Text>
    </div>
  )
}

function CartEmpty() {
  return (
    <UI.Text size="sm" className="text-gray-11 px-3">
      Your bag is empty
    </UI.Text>
  )
}

function CartSectionDivider() {
  return (
    <div className="px-3">
      <hr className="h-0 m-0 p-0 text-gray-6" />
    </div>
  )
}

function CartLineList({ lines }: { lines: LineList }) {
  return (
    <div className="flex-1 flex flex-col gap-3 p-3 overflow-y-auto">
      {lines.map((line) => (
        <CartLine key={line.node.id} line={line.node} />
      ))}
    </div>
  )
}

function CartLine({ line }: { line: Line }) {
  const formattedPrice = useMoney(line.cost.totalAmount)
  const optionsText = Shopify.getFormattedVariantOptions(
    line.merchandise.selectedOptions,
  )

  return (
    <div className="flex gap-4">
      <UI.Media
        src={line.merchandise.image?.url}
        alt={line.merchandise.image?.altText || ""}
        className="size-32 object-contain bg-gray-2 overflow-hidden shrink-0"
      />
      <div className="flex-1 flex flex-col gap-3 justify-between">
        <div>
          <UI.Text size="sm">{line.merchandise.product.title}</UI.Text>
          <UI.Text size="sm">{formattedPrice.localizedString}</UI.Text>
          <UI.Text size="xs" className="mt-3">
            {optionsText}
          </UI.Text>
        </div>
        <div className="flex items-center justify-between">
          <LineQuantitySelector line={line} />
          <RemoveLineButton lineId={line.id} />
        </div>
      </div>
    </div>
  )
}

function RemoveLineButton({
  lineId,
  ...props
}: JSX.IntrinsicElements["button"] & { lineId: string }) {
  return (
    <CartForm
      route="/cart"
      inputs={{ lineIds: [lineId] }}
      action={CartForm.ACTIONS.LinesRemove}
    >
      <UI.LinkButton type="submit" size="sm" {...props}>
        Remove
      </UI.LinkButton>
    </CartForm>
  )
}

function LineQuantitySelector({ line }: { line: Line }) {
  const prevQuantity = line.quantity - 1
  const nextQuantity = line.quantity + 1

  return (
    <div className="flex items-center shrink-0">
      <UpdateLineQuantityButton
        aria-label="Decrease quantity"
        line={{ id: line.id, quantity: prevQuantity }}
        disabled={line.quantity <= 1}
      >
        <Icon.Minus className="size-4" />
      </UpdateLineQuantityButton>
      <div className="px-1.5 flex items-center justify-center select-none">
        <UI.Text size="sm" className="tabular-nums text-center">
          {line.quantity}
        </UI.Text>
      </div>
      <UpdateLineQuantityButton
        aria-label="Increase quantity"
        line={{ id: line.id, quantity: nextQuantity }}
      >
        <Icon.Plus className="size-4" />
      </UpdateLineQuantityButton>
    </div>
  )
}

function UpdateLineQuantityButton({
  line,
  children,
  ...props
}: React.PropsWithChildren<
  JSX.IntrinsicElements["button"] & {
    line: CartLineUpdateInput
  }
>) {
  return (
    <CartForm
      route="/cart"
      inputs={{ lines: [line] }}
      action={CartForm.ACTIONS.LinesUpdate}
    >
      <UI.Button
        type="submit"
        variant="ghost"
        size="sm"
        className="flex items-center justify-center"
        {...props}
      >
        {children}
      </UI.Button>
    </CartForm>
  )
}

function CartFooter({
  checkoutUrl,
  cost,
}: {
  cost: CartData["cost"]
  checkoutUrl: CartData["checkoutUrl"]
}) {
  const formattedSubtotal = useMoney(cost.subtotalAmount)

  return (
    <div className="shrink-0 px-3">
      <div className="flex flex-col gap-3 py-3">
        <div className="flex flex-col gap-2">
          {[
            { label: "Subtotal", value: formattedSubtotal.localizedString },
            { label: "Shipping", value: "Calculated at checkout" },
          ].map(({ label, value }) => (
            <div key={label} className="flex items-center justify-between">
              <UI.Text size="sm">{label}</UI.Text>
              <UI.Text size="sm">{value}</UI.Text>
            </div>
          ))}
          <UI.Text size="xs" className="text-gray-11">
            Items from different creators ship separately, with individual shipping
            costs.
          </UI.Text>
        </div>

        <Link
          to={checkoutUrl}
          className={UI.classForButton({ size: "lg", className: "w-full" })}
        >
          Checkout
        </Link>

        <UI.Text size="xs" className="text-gray-11">
          By checking out, you agree to the{" "}
          {[
            { text: "Terms and Conditions", url: TERMS_AND_SERVICES_URL },
            { text: "Privacy Policy", url: PRIVACY_POLICY_URL },
          ].map(({ text, url }, index) => (
            <React.Fragment key={text}>
              <Link
                to={url}
                className="underline underline-offset-4"
                target="_blank"
                rel="noreferrer"
              >
                {text}
              </Link>
              {index === 0 && " and "}
            </React.Fragment>
          ))}
        </UI.Text>
      </div>
    </div>
  )
}

type LineList = CartData["lines"]["edges"]
type Line = LineList[number]["node"]

export { Cart }
