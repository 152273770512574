import type { OptimisticCartLineInput } from "@shopify/hydrogen"
import type { ProductVariantFragment } from "../../lib/shopify/types/storefront.generated"

import * as UI from "@iyk/ui"
import * as Sentry from "@sentry/remix"
import * as React from "react"

import { useFetcher } from "@remix-run/react"
import { CartForm } from "@shopify/hydrogen"
import { cn } from "../../lib/utils/classname.ts"

const ADD_TO_CART_FETCHER_KEY = "add-to-cart"

export function AddToCartButton({
  line,
  disabled,
  className,
  children,
  ...props
}: AddToCartButtonProps) {
  return (
    <CartForm
      route="/cart"
      inputs={{ lines: line ? [line] : [] }}
      action={CartForm.ACTIONS.LinesAdd}
      fetcherKey={ADD_TO_CART_FETCHER_KEY}
    >
      {(fetcher) => (
        <UI.Button
          type="submit"
          variant="primary"
          size="lg"
          className={cn("w-full", className)}
          disabled={disabled}
          onClick={(e) => {
            if (fetcher.state !== "idle") {
              e.preventDefault()
              return
            }
          }}
          {...props}
        >
          {children}
        </UI.Button>
      )}
    </CartForm>
  )
}

interface AddToCartButtonProps
  extends React.PropsWithChildren<JSX.IntrinsicElements["button"]> {
  line: OptimisticCartLineInput | null
}

// #region useOptimisticAddToCart

export function useOptimisticAddToCart(): UseOptimisticAddToCartReturnType {
  const fetcher = useFetcher({ key: ADD_TO_CART_FETCHER_KEY })

  const [data, setData] = React.useState<{
    selectedVariant: ProductVariantFragment | null
  }>({ selectedVariant: null })

  React.useEffect(() => {
    if (fetcher.state !== "submitting") return

    try {
      const cartFormInput = fetcher.formData?.get("cartFormInput")?.toString()
      if (!cartFormInput) {
        throw new Error("Property cartFormInput does not exist")
      }

      const data = JSON.parse(cartFormInput) as {
        inputs: { lines: OptimisticCartLineInput[] }
      }

      const selectedVariant = data.inputs.lines[0]?.selectedVariant
      if (!selectedVariant) {
        throw new Error("Property selectedVariant does not exist")
      }

      setData({ selectedVariant: selectedVariant as ProductVariantFragment })
    } catch (error) {
      Sentry.captureException(new Error("Failed to get cart form input"), {
        originalException: error,
      })
    }
  }, [fetcher.state])

  return data
}

type UseOptimisticAddToCartReturnType = {
  selectedVariant: ProductVariantFragment | null
}

// #endregion
